import { Link } from 'react-router-dom';
import Layout from '../../components/layout/MainLayout';
import { ReactComponent as BackgroundSVG } from '../../assets/images/shapes.svg';
import './HomePage.css';

const HomePage = () => {
  return (
    <Layout showVisitSite={true}>
      <div className='home-page'>
        <div className='container'>
          <div className='row justify-content-xl-start justify-content-center'>
            <div className='col-md-10 mb-4'>
              <h1 className='title'>Attachment At Work Assessment</h1>
              <p>
                This free assessment is developed by MindOnly to provide data-driven insights regarding your patterns 
                in relationships and important domains of psychological well-being. Although the measures we include in this assessment 
                are reliable and have undergone rigorous development, this assessment is by no means a diagnostic. If you have 
                significant concerns regarding your experiences in relationships and well-being, you should contact a qualified health 
                professional.
              </p>
              <Link
                to='/workplace-individual/situation'
                className='btn btn-secondary mt-2'
              >
                Start Assessment!
              </Link>
            </div>
          </div>
        </div>
        <BackgroundSVG
          className='background'
          color='var(--color-brand-main-700)'
        />
      </div>
    </Layout>
  );
};

export default HomePage;
