import Introduction, { Props } from './Introduction';
import coverImage from '../../../../assets/images/team-dynamics.png';
import {getTitleByKey, TEAM_DYNAMICS} from '../../../../libs/helpers/surveyIntroductionHelper';
import './Introduction.css';

const TeamDynamics = (props: Props) => {
  return (
    <Introduction title= {getTitleByKey(TEAM_DYNAMICS)} coverImage={coverImage} {...props}>
      Team dynamics are essentially the outcome of cognitive processes 
      (such as emotions, thoughts, and motivations) combined with the specific relationships stemming from within the team. 
      Such dynamics can influence the team’s overall functioning and effectiveness. The challenge is: 
      it is hard to know the attitudes, perceptions or feelings that are underlying the dynamics of a group.
      <br />
      <br />
      <b>
        This section includes measures that ask questions about your experiences
        of working in this team, with a focus on relationships.
      </b>
      <br />
      <br />
      This assessment is designed to gather your honest and open feedback. Rest
      assured, no identifiable information will be shared with anyone and all
      results are reported as averages. Your responses will remain confidential.
      <br />
      <br />
    </Introduction>
  );
};

export default TeamDynamics;
