import { Link } from 'react-router-dom';
import Layout from '../../components/layout/MainLayout';
import { ReactComponent as BackgroundSVG } from '../../assets/images/shapes.svg';
import './HomePage.css';
import OpenGraph from '../../components/OpenGraph';
import useOpenGraph from '../quiz/start/useOpenGraph';

const TeamHomePage = () => {
  const ogProps = useOpenGraph();
  return (
    <>
    <OpenGraph {...ogProps} />
    <Layout showVisitSite={true}>
      <div className='home-page'>
        <div className='container'>
          <div className='row justify-content-xl-start justify-content-center'>
            <div className='col-md-10 mb-4'>
              <h1 className='title'>Attachment at Work Assessment: Team Dynamics & Well-Being</h1>
              <p>This team assessment is developed by MindOnly to provide data-driven insights regarding your team's
                attachment patterns, team dynamics and psychological well-being. You will receive a personal report containing scores
                that are personal and private to you.</p>
              <p>Finally, although the measures we include in this assessment are reliable and have undergone rigorous development,
                this assessment is by no means a diagnostic. If you have significant concerns regarding your experiences in
                relationships and well-being, you should contact a qualified health professional.</p>
              <p>This should take you no more than 10-15 minutes to complete. Please answer honestly -
                we will never report anything that identifies you or your personal scores in the team reports.</p>
              <Link
                to='/workplace-team/situation'
                className='btn btn-secondary mt-2'
              >
                Start Assessment!
              </Link>
            </div>
          </div>
        </div>
        <BackgroundSVG
          className='background'
          color='var(--color-brand-main-700)'
        />
      </div>
    </Layout>
    </>
  );
};

export default TeamHomePage;
