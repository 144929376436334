import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getHiddenSurveyStatIndexes } from '../../libs/helpers/surveyStatHelper';
import { getQuizLastStepRoute } from '../../libs/helpers/quizHelper';
import {
  getQuizSessionToken,
  removeQuizAccessToken,
  removeQuizSessionToken,
} from '../../libs/services/quizAccessService';
import QuizStat from '../../interfaces/QuizStat';
import SurveyStat from '../../interfaces/SurveyStat';

interface Props {
  quizStat: QuizStat | null;
  getQuizStat: (params?: object) => Promise<any>;
  startSurveyStat: (id: number, params?: object) => Promise<any>;
  skipSurveyStat: (id: number, params?: object) => Promise<any>;
  completeSurveyStat: (id: number, params?: object) => Promise<any>;
  invalidateQuizStatState: () => Promise<any>;
  invalidateSurveyQuestionsState: () => Promise<any>;
}

const useQuizPage = ({
  quizStat,
  getQuizStat,
  startSurveyStat,
  skipSurveyStat,
  completeSurveyStat,
  invalidateQuizStatState,
  invalidateSurveyQuestionsState,
}: Props) => {
  const { slug = '', step = '' } = useParams();

  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const [incrementStep, setIncrementStep]: [boolean, any] = useState(false);
  const [showDisclaimer, setShowDisclaimer]: [boolean, any] = useState(true);
  const [currentStep, setCurrentStep]: [string, any] = useState('');
  const [stepProgress, setStepProgress]: [number, any] = useState(0);
  const [navbar, setNavbar]: [object | null, any] = useState(null);
  const [searchParams]: any = useSearchParams();

  const navigate = useNavigate();

  const surveyStats = quizStat?.survey_stats ?? [];
  const surveyStatCount = surveyStats?.length || 0;

  useEffect(() => {
    scrollToWindowTop();
  }, [isLoading]);

  useEffect(() => {
    !isQuizStatLoaded() && fetchQuizStat();

    return () => {
      invalidateQuizStatState();
      invalidateSurveyQuestionsState();
    };
  }, []);

  useEffect(() => {
    invalidateSurveyQuestionsState();
  }, [currentStep]);

  useEffect(() => {
    if (isQuizStatLoaded()) {
      if (incrementStep) {
        setIncrementStep(false);
      }

      updateCurrentStep(step, incrementStep);
    }
  }, [quizStat]);

  const isQuizStatLoaded = (): boolean => quizStat !== null;

  const fetchQuizStat = (): void => {
    getQuizStat().catch(({ code, message }) => {
      if (code === 403) {
        redirectToLastStep();
      } else {
        toast.error(message);
        removeQuizAccessToken(slug);
        removeQuizSessionToken();
        redirectToHome();
      }
    });
  };

  const stepBack = (): void => {
    setStepProgress(100);
    updateCurrentStep(currentStep, true, -1);
  };

  const startStep = (): Promise<any> => {
    return startSurveyStat(surveyStat.id).catch((e) => {
      toast.error(e.message);
    });
  };

  const skipStep = (): Promise<any> => {
    setIncrementStep(true);
    setStepProgress(0);
    return skipSurveyStat(surveyStat.id);
  };

  const completeStep = (): Promise<any> => {
    setIncrementStep(true);
    setStepProgress(0);
    return completeSurveyStat(surveyStat.id);
  };

  const updateCurrentStep = (
    step: string,
    incrementStep: boolean = false,
    increment: number = 1,
  ): void => {
    let nextIndex = findFirstIncompleteIdx();

    if (incrementStep) {
      step = getIncrementedStep(step, increment);
    }

    if (isStepValid(step)) {
      const stepIndex = getIndexByStep(step);
      nextIndex = Math.min(nextIndex, stepIndex);
    } else if (incrementStep) {
      updateCurrentStep(step, true, increment);
      return;
    }

    if (nextIndex < 0) {
      redirectToLastStep();
      return;
    }

    const nextStep = geStepByIndex(nextIndex);
    setCurrentStep(nextStep);
    redirectToStep(nextStep);
  };

  const findFirstIncompleteIdx = (): number => {
    return surveyStats.findIndex(
      (x) => x.is_completed === 0 && x.is_skipped === 0,
    );
  };

  const isStepValid = (step: string): boolean => {
    const stepSurveyStat = surveyStats.some(
      ({ quiz_survey, is_completed }: SurveyStat) => {
        const { slug, is_immutable } = quiz_survey;
        return slug === step && (is_immutable === 0 || !is_completed);
      },
    );

    return stepSurveyStat && !isStepHidden(step);
  };

  const isStepHidden = (step: string) => {
    const stepIndex = getIndexByStep(step);
    const hiddenIndexes = getHiddenSurveyStatIndexes(surveyStats);

    return hiddenIndexes.includes(stepIndex);
  };

  const canStepBack = () => {
    const prevStep = getIncrementedStep(step, -1);

    return surveyStats.some(
      ({ quiz_survey }: SurveyStat) =>
        quiz_survey?.slug === prevStep && quiz_survey?.is_immutable === 0,
    );
  };

  const getIndexByStep = (step: string) => {
    return surveyStats.findIndex(
      ({ quiz_survey }: SurveyStat) => quiz_survey.slug === step,
    );
  };

  const geStepByIndex = (index: number): string => {
    return surveyStats[index]?.quiz_survey.slug || '';
  };

  const getIncrementedStep = (
    currentStep: string,
    increment: number = 1,
  ): string => {
    const currentIndex = getIndexByStep(currentStep);

    const nextIndex = currentIndex + increment;
    const nextStepSlug = geStepByIndex(nextIndex);

    return nextStepSlug;
  };

  const redirectToHome = (): void => navigate('/');

  const redirectToLastStep = (): void => {
    const uri = getQuizLastStepRoute(slug) + `?token=` + getQuizSessionToken();
    navigate(uri);
  };

  const redirectToStep = (step: string): void => {
    const uri = `../${step}?${searchParams.toString()}`;
    navigate(uri);
  };

  const scrollToWindowTop = () => {
    const options: any = {
      top: 80,
      left: 0,
      behavior: 'instant',
    };

    window.scrollTo(options);
  };

  const currentIndex = getIndexByStep(currentStep);
  const surveyStat = surveyStats?.[currentIndex];

  return {
    isLoading,
    setIsLoading,
    quizStat,
    surveyStat,
    showDisclaimer,
    setShowDisclaimer,
    surveyStatCount,
    currentIndex,
    startStep,
    stepBack,
    skipStep,
    completeStep,
    navbar,
    setNavbar,
    stepProgress,
    setStepProgress,
    canStepBack,
  };
};

export default useQuizPage;
