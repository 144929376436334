import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getResults } from '../../../libs/api/quizStatApi';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
} from '../../../libs/services/quizAccessService';
import QuizLayout from '../../../components/layout/QuizLayout';
import Loader from '../../../components/quiz/loader/Loader';
import './QuizCompletedPage.css';

const QuizCompletedPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [payload, setPayload]: [any, any] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = () => {
    setIsLoading(true);
    getResults()
      .then((payload) => {
        setPayload(payload);
        setIsLoading(false);
        removeQuizTokens();
      })
      .catch(({ message }) => {
        removeQuizTokens();
        navigate('/');
        toast.error(message);
      });
  };

  const removeQuizTokens = () => {
    removeQuizAccessToken(slug);
    removeQuizSessionToken();
  };

  return (
    <QuizLayout showFooter={!isLoading} header={{ showVisitSite: true }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='quiz-completed-page'>
          <div className='col-md-6'>
            <h2 className='mb-3'>{payload?.message}</h2>
            <p className='mb-0'>You will shortly receive an email that will give you access to your findings in a comprehensive report. 
              This information is private to you and will not be shared with any member of your team. 
              Your team’s report will be shared after the data-collection window closes. 
              Reports will only be generated once a minimum of four team members have completed their assessments.</p>
            <Link to='/' className='btn btn-secondary w-100 mt-5'>
              Home
            </Link>
          </div>
        </div>
      )}
    </QuizLayout>
  );
};

export default QuizCompletedPage;
