import { useParams } from 'react-router-dom';
import * as quizHelper from '../../../libs/helpers/quizHelper';

interface OpenGraphConfig {
  title: string;
  description?: string;
  image?: string;
}

const useOpenGraph = (): OpenGraphConfig => {
  const { slug = '' } = useParams();

  const defaultConfig: OpenGraphConfig = {
    title: 'MindOnly | Set Up Profile',
  };

  const config: { [key: string]: OpenGraphConfig } = {
    [quizHelper.WORKPLACE_INDIVIDUAL]: {
      title: 'MindOnly | Workplace Individual',
    },
    [quizHelper.WORKPLACE_TEAM]: {
      title: 'MindOnly | Workplace Team',
      image: `${process.env.PUBLIC_URL}/team_og_img.jpeg`,
    },
  };

  return config[slug] || defaultConfig;
};

export default useOpenGraph;
