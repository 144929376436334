import React from 'react';
import { connect } from 'react-redux';
import Quiz from '../../../../interfaces/Quiz';
import OpenGraph from '../../../../components/OpenGraph';
import QuizLayout from '../../../../components/layout/QuizLayout';
import Situation from '../../../../components/quiz/situation/Situation';
import useOpenGraph from '../useOpenGraph';
import useStartForm from './useStartForm';
import useStartQuiz from '../useStartQuiz';
import Loader from '../../../../components/quiz/loader/Loader';
import WorkplacePopu from '../../poup/WorkplacePopup';

interface Props {
  quiz: Quiz;
}

const StartQuizPage: React.FC<Props> = (props) => {
  const ogProps = useOpenGraph();
  const form = useStartForm(props);

  const {
    isLoading,
    showDisclaimer,
    setShowDisclaimer,
    header,
    navbar,
    progress,
    situationSurvey,
    canGoForward,
    stepForward,
  } = useStartQuiz(props);

  const onSubmit = () => {
    canGoForward() ? stepForward() : form.submitForm();
  };

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout header={header} proggres={progress} navbar={navbar}>
        {isLoading && <Loader />}
        <Situation
          situationSurvey={situationSurvey}
          form={form}
          onSubmit={onSubmit}
        />
        <WorkplacePopu
          isOpened={showDisclaimer}
          setIsOpened={setShowDisclaimer}
          title='Attachment At Work & Well-Being'
          text='You are taking part in the Attachment At Work assessment.'
        />
      </QuizLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
