import Introduction, { Props } from './Introduction';
import coverImage from '../../../../assets/images/human-attachment.png';
import {getTitleByKey, HUMAN_ATTACHMENT} from '../../../../libs/helpers/surveyIntroductionHelper';
import './Introduction.css';

const HumanAttachment = (props: Props) => {
  
  return (
    <Introduction title={getTitleByKey(HUMAN_ATTACHMENT)} coverImage={coverImage} {...props}>
      Human attachment plays an essential role in our survival as babies, as
      well our relationships and overall well-being later in life. Since our
      attachment styles are formed so early, we often neither remember much about this
      stage of development nor have control over it. Therefore, our attachment
      traits are typically subconscious and automatic.
      <br />
      <br />
      <b>
        This section asks about the attachment patterns you experience in your
        personal and workplace relationships.
      </b>
      <br />
      <br />
    </Introduction>
  );
};

export default HumanAttachment;
