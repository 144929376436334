import HumanAttachment from '../../components/quiz/survey/intro/HumanAttachment';
import PsychologicalWellBeing from '../../components/quiz/survey/intro/PsychologicalWellBeing';
import TeamDynamics from '../../components/quiz/survey/intro/TeamDynamics';

export const HUMAN_ATTACHMENT = 'human-attachment';
export const TEAM_DYNAMICS = 'team-dynamics';
export const PSYCH_WELL_BEING = 'psych-well-being';

const components: any = {
  [HUMAN_ATTACHMENT]: HumanAttachment,
  [TEAM_DYNAMICS]: TeamDynamics,
  [PSYCH_WELL_BEING]: PsychologicalWellBeing,
};

const titles: any = {
  [HUMAN_ATTACHMENT]: 'Human Attachment',
  [TEAM_DYNAMICS]: 'Team Dynamics',
  [PSYCH_WELL_BEING]: 'Psychological Well-Being',
};

export const getComponentByKey = (key: string): any => {
  return components[key] ?? null;
};

export const getTitleByKey = (key: string): any => {
  return titles[key] ?? null;
};