import { Link } from 'react-router-dom';
import env from '../../env';
import styles from './footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className='container d-flex flex-column justify-content-between h-100'>
        <div className='d-flex flex-wrap'>
          <div className='me-auto pe-3 mb-2 mb-sm-0'>
            © 2020-{currentYear} MindOnly Pty Ltd
          </div>
          <Link
            to={`${env.mindOnlyUrl}/terms`}
            className={`${styles.link} me-3 mx-md-5`}
            target='_blank'
          >
            Terms
          </Link>
          <Link
            to={`${env.mindOnlyUrl}/privacy-policy`}
            className={styles.link}
            target='_blank'
          >
            Privacy
          </Link>
        </div>
        <div className={styles.disclaimer}>
          The MindOnly’s content courses are for informational and
          educational purposes only. Our website and products are not intended
          to be a substitute for professional medical and/or psychological
          advice, diagnosis, or treatment.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
