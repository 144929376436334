import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import LimitReachedPage from '../pages/quiz/limit/LimitReachedPage';
import UnsubscribePage from '../pages/unsubscribe/UnsubscribePage';
import QuizRouter from './QuizRouter';
import SuccessMessagePage from '../pages/success-message/SuccessMessagePage';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route
        path='/success-message/:message'
        element={<SuccessMessagePage />}
      />
      <Route path='/limit-reached' element={<LimitReachedPage />} />
      <Route path='/unsubscribe' element={<UnsubscribePage />} />
      <Route path='/:slug/*' element={<QuizRouter />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
